.custom-table table {
    border-radius: 20px !important;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.065);
    overflow: hidden;
    .ant-table-thead {
        th{
            background-color: var(--primary);
            font-family: 'Comfortaa', cursive;
            font-weight: 600;
            font-size: 16px;
            color: #fff;
        }
    }
    .ant-table-tbody{
        td{
            font-family: 'Comfortaa', cursive;
            font-size: 16px;
            .ant-tag{
                font-family: 'Comfortaa', cursive;
                font-size: 14px;
            }
            .action-btn{
                border: none;
                background-color: transparent;
                color: var(--secondary);
                font-size: 20px;
                padding: 5px 10px;
                margin: 0px 5px;
                transition: 0.3s;
                &:hover{
                    color: var(--primary);
                }
            }
        }
    }
}

.custom-modal {
    .ant-modal-content{
        padding: 0px;
        border-radius: 18px;
        overflow: hidden;
    }
    .ant-modal-header{
        padding: 15px 20px;
        margin-bottom: 0px;
        // background-color: var(--primary);
        .ant-modal-title{
            font-family: 'Comfortaa', cursive;
            font-weight: 600;
            font-size: 20px;
            text-align: center;
            color: #000;
        }
    }
    .ant-modal-body{
        padding: 0px 40px;
    }
    .ant-modal-footer{
        text-align: center;
        padding-bottom: 20px;
        button{
            font-family: 'Comfortaa', cursive;
            font-weight: 600;
            font-size: 16px;
            color: #7c7c7c;
            margin-inline-start: 0px !important;
            margin: 0px 10px;
            padding: 8px 25px 6px;
            border-radius: 8px;
            height: auto;
            &:disabled{
                opacity: 0.8;
                color: #fff;
                background-color: var(--primary);
            }
            &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
                border-color: var(--primary);
                color: var(--primary);
            }
            &.ant-btn-primary{
                background-color: var(--primary);
                color: #fff;
                &:hover{
                    background-color: var(--primary);
                }
            }
        }
    }
    .ant-modal-close{
        top: 20px;
        color: #fff;
        &:hover{
            background-color: transparent;
            color: #ce0e0e;
        }
        .ant-modal-close-x{
            font-size: 20px;
        }
    }
}

.ant-notification-notice-message{
    margin-bottom: 0px !important;
}

.custom-table .ant-table{
    background-color: transparent;
}

.custom-table .ant-table .ant-table-tbody{
    background-color: #fff;
}

.custom-confirm{
    .popup-icon{
        display: inline;
        color: #e3c20b;
        font-size: 22px;
    }
    h5{
        font-family: 'Comfortaa', cursive;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 0px;
    }
    p{
        font-family: 'Comfortaa', cursive;
        font-size: 16px;
        margin-bottom: 0px;
    }
    .ant-modal-confirm-btns{
        margin-top: 20px;
        text-align: center;
        button{
            font-family: 'Comfortaa', cursive;
        }
    }
}

.custom-datepicker{
    border: 2px solid var(--primary);
    padding: 8px 10px;
    border-radius: 8px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.075);
    background: transparent;
    .ant-picker-input {
        input{
            font-family: 'Comfortaa', cursive;
            font-size: 14px;
            color: #fff;
        }
    }
    &.bordered{
        width: 100%;
        border: 1px solid #868686;
        box-shadow: none;
    }
    .anticon,
    .ant-picker-separator{
        color: #fff9;
    }
    .ant-picker-active-bar{
        background: var(--primary);
        height: 3px;
    }
    &:hover,
    &:focus{
        border-color: var(--primary);
    }
}

.input-error{
    .custom-datepicker{
        border-color: #df1010;
        input{
            border: none;
        }
    }    
}

.custom-image-loader{
    width: 100% !important;
    height: 300px !important;
    border-radius: 12px !important;
}

.ant-pagination.custom-pagination{
    .ant-pagination-item{
      font-family: 'Comfortaa', cursive;
      font-weight: 600;
      font-size: 16px;
      color: #000;
      background-color: #fff;
      min-width: 30px;
      height: 30px;
      line-height: 27px;
      &:hover{
        background-color: #d4d4d4;
      }
    }
    .ant-pagination-item-link{
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      color: #7c7c7c;
      border-radius: 6px;
    }
    .ant-pagination-item-active{
      color: #fff;
      background-color: var(--primary);
      &:hover{
        background-color: var(--primary);
      }
    }
    .ant-pagination-disabled .ant-pagination-item-link{
      color: var(--secondary);
    }
    .ant-pagination-prev,
    .ant-pagination-next{
        min-width: 25px;
        height: 30px;
    }
  }
  

.ant-select-dropdown .ant-select-item-option-content,
.ant-select-selection-item{
    font-family: 'Comfortaa', cursive;
    font-weight: 600;
    font-size: 14px;
}

.ant-select.custom-select {
    height: auto !important;
    .ant-select-arrow{
        color: #fff;
    }
    .ant-select-selector{
        width: 100%;
        border: 2px solid var(--primary) !important;
        border-radius: 6px !important;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.075);
        height: auto !important;
        background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(5px);
        padding: 7px 15px !important;
        .ant-select-selection-search-input {
            font-family: 'Comfortaa', cursive !important;
            font-size: 18px;
            color: #fff !important;
            height: auto !important;
            padding: 7px 0px;
        }
        .ant-select-selection-item{
            color: #fff;
            font-size: 16px;
        }
    }
    .ant-select-selection-placeholder {
        font-family: 'Comfortaa', cursive !important;
        font-size: 16px !important;
    }
    .ant-select-selection-placeholder{
        color: #fff9;
        inset-inline-start: 15px !important;
    }
    .ant-select-selection-search{
        margin-inline-start: 0px !important;
    }
}

.ant-tooltip-inner{
    font-family: 'Comfortaa', cursive !important;
}

.ant-popconfirm{
    .ant-popover-inner{
        // background-color: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(5px);
        padding: 20px;
    }
    .ant-popconfirm-message >.ant-popconfirm-message-icon .anticon{
        color: var(--primary);
        font-size: 20px;
    }
    .ant-popconfirm-message-text{
        .ant-popconfirm-title{
            font-family: 'Comfortaa', cursive !important;
            font-weight: 700;
            font-size: 16px;
            color: #000;
            text-align: center;
        }
        .ant-radio-wrapper{
            span{
                font-family: 'Comfortaa', cursive !important;
                color: #000;
            }
        }
    }
    .ant-popconfirm-message-icon{
        display: none;
    }

    .ant-popconfirm-buttons{
        button{
            font-family: 'Comfortaa', cursive !important;
            height: auto;
            padding: 4px 15px;
            border-radius: 6px;
            &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
                border-color: var(--primary);
                color: var(--primary);
            }
            &.ant-btn-primary{
                background-color: var(--primary);
                &:hover{
                    background-color: var(--primary);
                }
            }
        }
    }
}

.ant-radio-wrapper {
    .ant-radio-checked .ant-radio-inner{
        background-color: var(--primary);
        border-color: var(--primary);
    }
    &.ant-radio-inner{
        border-color: var(--primary);
    }
    &:hover{
        .ant-radio-inner{
            border-color: var(--primary);
        }
    }
}

.ant-switch.ant-switch-checked{
    background: var(--primary);
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled){
    background: #e28103;
}

.ant-notification-notice-message{
    font-family: 'Comfortaa', cursive !important;
}

@media (max-width: 767px){
    .custom-table{
        width: 100%;
        .ant-table{
            overflow-x: scroll;
        }
    }

    .pt-m-0{
        padding-top: 0px;
    }
}