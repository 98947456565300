@font-face {
    font-family: 'Ethnocentric';
    src: url(../font/ethnocentric.otf);
}

:root{
    --primary: #ee8700;
    --secondary: #3d3d45;
    --background: #FAF9FF;
}

*{
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
}

body{
    font-family: 'Comfortaa', cursive !important;
    font-weight: 500;
    background-color: var(--background) !important;
}

input:focus,
button:focus{
    outline: none !important;
}

input::placeholder,
textarea::placeholder{
    color: #bfbfbf !important;
}

a {
    text-decoration: none !important;
}

.w-300{
    width: 300px;
}

body > div:not([id]):not([class]) canvas{
    display: none !important;
}

@media (max-width: 767px) {
    .w-sm-100{
        width: 100% !important;
    }
}